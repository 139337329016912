import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { Dropdown, SearchableDropdown, NativeDropdown } from '@entur/dropdown';
import { SmallExpandableAlertBox } from '@entur/alert';
import { ResetIcon } from '@entur/icons';
import { IconButton } from '@entur/button';
import { ExpandableText } from '@entur/expand';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { dropdownItems, countries, cities, fuzzyMatch } from './dropdownItems';
import { CodeBlock } from '~/components/CodeBlock';
import doPic from './DropdownDo.png';
import dontPic from './DropdownDont.png';
import { Link as LinkText, CodeText } from '@entur/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [selectedTraveler, setSelectedTraveler] = React.useState(null)\n  const [selectedCountry, setSelectedCountry] = React.useState(null)\n  return (\n    <>\n      <Dropdown\n        items={dropdownItems}\n        label=\"Reisende\"\n        selectedItem={selectedTraveler}\n        onChange={setSelectedTraveler}\n        style={{ marginBottom: \'2rem\' }}\n      />\n      <SearchableDropdown\n        items={countries}\n        label=\"Land\"\n        placeholder=\"F.eks. Norge\"\n        selectedItem={selectedCountry}\n        onChange={setSelectedCountry}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      margin: 'auto',
      width: 'max(15rem, 40%)'
    }} mdxType="Playground">
  {() => {
        const [selectedTraveler, setSelectedTraveler] = React.useState(null);
        const [selectedCountry, setSelectedCountry] = React.useState(null);
        return <>
        <Dropdown items={dropdownItems} label="Reisende" selectedItem={selectedTraveler} onChange={setSelectedTraveler} style={{
            marginBottom: '2rem'
          }} mdxType="Dropdown" />
        <SearchableDropdown items={countries} label="Land" placeholder="F.eks. Norge" selectedItem={selectedCountry} onChange={setSelectedCountry} mdxType="SearchableDropdown" />
      </>;
      }}
    </Playground>
    <br />
    <SmallExpandableAlertBox title="Migrering fra v4 -> v5" variant="information" mdxType="SmallExpandableAlertBox">
Hvis du ikke ønsker å migrere alle <CodeText mdxType="CodeText">Dropdown</CodeText>-er med en gang kan du:
      <ul>
  <li>
    Endre navn og import for eksisterende <CodeText mdxType="CodeText">Dropdown</CodeText> du ikke
    vil migrere enda til <CodeText mdxType="CodeText">DropdownDeprecated</CodeText>
  </li>
      </ul>
I v5 er dropdown kontrollert, så du må selv holde på state for valgt element.
Dette løser du ved å:
      <ul>
  <li>
    Hvis Dropdown-en er søkbar fjerner du <CodeText mdxType="CodeText">searchable</CodeText>
    -prop-en og endrer import til <CodeText mdxType="CodeText">SearchableDropdown</CodeText>
  </li>
  <li>
    Bruke <CodeText mdxType="CodeText">React.useState</CodeText> eller lignende til lagring av
    valgt element
  </li>
  <li>
    Bytt ut <CodeText mdxType="CodeText">value</CodeText> med <CodeText mdxType="CodeText">selectedItem</CodeText>. Gi
    inn verdi fra state her. Obs, typen på denne er nå kun{' '}
    <CodeText mdxType="CodeText">NormalizedDropdownItemType</CodeText>
  </li>
  <li>
    Sørg for at <CodeText mdxType="CodeText">onChange</CodeText> oppdaterer state med det valgte
    elementet send inn som argument
  </li>
      </ul>
    </SmallExpandableAlertBox>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <p><inlineCode parentName="p">{`Dropdown`}</inlineCode>{` og `}<inlineCode parentName="p">{`SearchableDropdown`}</inlineCode>{` er komponenter som lar brukeren utføre ett valg blant
mange ulike valgalternaltiver. Skal flere valg være mulig på en gang må du bruke
`}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/multi-select"
      }}>{`MultiSelect`}</a>{`. Komponenten
viser frem en liste med valg bassert på `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en, der `}<inlineCode parentName="p">{`items`}</inlineCode>{` kan være en liste med `}<inlineCode parentName="p">{`string`}</inlineCode>{` eller
objekter med `}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<inlineCode parentName="p">{`label`}</inlineCode>{` og `}<inlineCode parentName="p">{`icons`}</inlineCode>{` - eller en blanding av disse. For å dekke situasjoner
der valgalternaltiver kommmer fra et API eller lignende støtter `}<inlineCode parentName="p">{`items`}</inlineCode>{` også
synkrone og asynkrone funksjoner som returnerer samme type liste som `}<inlineCode parentName="p">{`items`}</inlineCode>{` aksepterer.`}</p>
    <h3 {...{
      "id": "kom-i-gang"
    }}>{`Kom i gang`}</h3>
    <p>{`Dropdown-ene har tre påkrevde props: `}<inlineCode parentName="p">{`label`}</inlineCode>{`, `}<inlineCode parentName="p">{`items`}</inlineCode>{` og `}<inlineCode parentName="p">{`selectedItem`}</inlineCode>{`. I tillegg har den også
`}<inlineCode parentName="p">{`onChange`}</inlineCode>{` som ikke er påkrevd, men som er nødvendig for å gjøre komponenten interaktiv.`}</p>
    <p><inlineCode parentName="p">{`label`}</inlineCode>{` er feltets navn og beskriver hva du velger. `}<inlineCode parentName="p">{`items`}</inlineCode>{` tar inn listen med valgalternaltiver,
se `}<a parentName="p" {...{
        "href": "#liste-med-valgalternaltiver"
      }}>{`Liste med valgalternaltiver`}</a>{` for mer info. `}<inlineCode parentName="p">{`selectedItem`}</inlineCode>{` og `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` jobber sammen
for å holde styr på state for valgt element. `}<inlineCode parentName="p">{`selectedItem`}</inlineCode>{` skal være av typen
`}<inlineCode parentName="p">{`NormalizedDropdownItemType`}</inlineCode>{` eller `}<inlineCode parentName="p">{`null`}</inlineCode>{` og `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` skal være en funksjon som tar inn et valgt
element og oppdaterer verdien på `}<inlineCode parentName="p">{`selectedItem`}</inlineCode>{` til dette elementet. Se `}<a parentName="p" {...{
        "href": "#vanlig-dropdown"
      }}>{`Vanlig dropdown-`}</a>{`
og `}<a parentName="p" {...{
        "href": "#s%C3%B8kbar-dropdown-1"
      }}>{`Søkbar dropdown`}</a>{`-eksemplene for å se dette i praksis.`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`// Definition of NormalizedDropdownItemType
type NormalizedDropdownItemType<ValueType = string> = 
      { label: string, value: ValueType, icons?: React.ComponentType<any>[] }`}
    </CodeBlock>
    <p>{`Siden `}<inlineCode parentName="p">{`NormalizedDropdownItemType`}</inlineCode>{` er en generisk type så kan `}<inlineCode parentName="p">{`value`}</inlineCode>{` være hvilken som helst type. TypeScript vil
da også gi deg riktig type ut i `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`. Den er satt til standard som `}<inlineCode parentName="p">{`string`}</inlineCode>{` da dette er vanligste praksis. `}</p>
    <h3 {...{
      "id": "liste-med-valgalternaltiver"
    }}>{`Liste med valgalternaltiver`}</h3>
    <p>{`Listen med valgalternaltiver sendes inn gjennom `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en. `}<inlineCode parentName="p">{`items`}</inlineCode>{` har typen `}<inlineCode parentName="p">{`PotentiallyAsyncDropdownItems`}</inlineCode>{`
som vil si den støtter tre typer input: liste med `}<inlineCode parentName="p">{`DropdownItemType`}</inlineCode>{`, synkrone funksjoner og asynkrone
funksjoner – de to siste kan du lese mer om under `}<a parentName="p" {...{
        "href": "#hente-valgalternaltiver-fra-nettverkskall"
      }}>{`Hente valgalternaltiver fra nettverkskall`}</a>{`.`}</p>
    <p><inlineCode parentName="p">{`DropdownItemType`}</inlineCode>{` er enten en `}<inlineCode parentName="p">{`string`}</inlineCode>{` eller et objekt med `}<inlineCode parentName="p">{`label`}</inlineCode>{`, `}<inlineCode parentName="p">{`value`}</inlineCode>{` og valgfritt `}<inlineCode parentName="p">{`icons`}</inlineCode>{` – se under.`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`// Definition of DropdownItemType
type DropdownItemType<ValueType = string> = 
      string |
      { label: string, value: ValueType, icons?: React.ComponentType<any>[] }`}
    </CodeBlock>
    <p>{`Disse kan også blandes slik at følgende også vil være et gyldig `}<inlineCode parentName="p">{`items`}</inlineCode>{`-input:`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`// Valid mixed type items input
const dropdownItems = [
  "choice1",
  { label: "Second choice", value: "choice2" },
  { label: "Third choice", value: "choice3", icons: [FunnyIcon1, FunnyIcon2] }
]`}
    </CodeBlock>
    <h3 {...{
      "id": "søkbar-dropdown"
    }}>{`Søkbar dropdown`}</h3>
    <p>{`Dropdown-en er også tilgjenglig i en søkbar versjon kalt `}<inlineCode parentName="p">{`SearchableDropdown`}</inlineCode>{`. Denne fungerer i utgangspunktet
helt likt som `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{`, men har i tillegg støtte for å filterer på de tilgjengelige valgmulighetene. Som standard
er filtreringen bassert på en enkel regex som sjekker om input-et er en del av valgalternaltivet, ønsker du å endre
denne kan du skrive din egen filtreringsfunksjon – se `}<a parentName="p" {...{
        "href": "#s%C3%B8kbar-dropdown-med-egendefinert-filtreringsfunksjon"
      }}>{`Egendefinert filtreringsfunksjon`}</a>{`.`}</p>
    <p>{`Se et eksempel på hvordan du bruker `}<inlineCode parentName="p">{`SearchableDropdown`}</inlineCode>{` under `}<a parentName="p" {...{
        "href": "#s%C3%B8kbar-dropdown-1"
      }}>{`Søkbar dropdown-eksempelet`}</a>{`.`}</p>
    <h4 {...{
      "id": "egendefinert-filtreringsfunksjon"
    }}>{`Egendefinert filtreringsfunksjon`}</h4>
    <p>{`Du kan gi inn din egen filtreringsfunksjon til `}<inlineCode parentName="p">{`SearchableDropdown`}</inlineCode>{` gjennom prop-en `}<inlineCode parentName="p">{`itemFilter`}</inlineCode>{`. Dette kan være
nyttig hvis du f.eks ønsker å implementere `}<em parentName="p">{`fuzzy search`}</em>{` eller ignorere noen tegn fra input-et når du filtrerer.
`}<inlineCode parentName="p">{`itemFilter`}</inlineCode>{` tar inn en funksjon med `}<inlineCode parentName="p">{`item`}</inlineCode>{` (valgalternaltivet som skal sjekkes mot filtreringen) og `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{`
(det bruker for øyeblikket har skrevet inn i inputfeltet) som input. Funksjonen skal returnere `}<inlineCode parentName="p">{`true`}</inlineCode>{` eller `}<inlineCode parentName="p">{`false`}</inlineCode>{`
avhengig av om gjeldende `}<inlineCode parentName="p">{`item`}</inlineCode>{` skal være med i listen over valg eller ikke.`}</p>
    <p>{`Se eksempel på dette under `}<a parentName="p" {...{
        "href": "#s%C3%B8kbar-dropdown-med-egendefinert-filtreringsfunksjon"
      }}>{`Søkbar dropdown med egendefingert filtreringsfunksjon-eksempelet`}</a>{`.`}</p>
    <h3 {...{
      "id": "hente-valgalternaltiver-fra-nettverkskall"
    }}>{`Hente valgalternaltiver fra nettverkskall`}</h3>
    <p>{`Hvis listen din med valg skal hentes fra et API eller lignende er det mulig å sende inn en memoisert funksjon til `}<inlineCode parentName="p">{`items`}</inlineCode>{`
i stedet for en liste med valg. Denne funksjonen kan enten være synkron eller asynkron og må enten returnere en
liste med `}<inlineCode parentName="p">{`DropdownItemType`}</inlineCode>{` direkte eller gjennom et `}<inlineCode parentName="p">{`Promise`}</inlineCode>{`. OBS: du må huske å `}<a parentName="p" {...{
        "href": "https://react.dev/reference/react/useCallback"
      }}>{`memoisere funksjonen`}</a>{` ved å bruke
`}<inlineCode parentName="p">{`React.useCallback`}</inlineCode>{`, ellers vil den kjøre funksjonen din oftere enn nødvendig!`}</p>
    <p>{`For søkbare dropdowns kan funksjonen i `}<inlineCode parentName="p">{`items`}</inlineCode>{` også bruke `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{`-verdien som sendes inn å f.eks gjøre en query mot
et API-et. I dette tilfellet kan du også bruke prop-en `}<inlineCode parentName="p">{`debounceTimeout`}</inlineCode>{` til å bestemme hvor lenge det skal
ventes før et nytt kall mot funksjonen skal kjøres etter brukeres slutter å skrive. `}</p>
    <p>{`For å avbryte utdaterte kall og hindre 'state update of unmounted component' kan du bruke `}<inlineCode parentName="p">{`abortControllerRef`}</inlineCode>{` som sendes inn som andre argument til funksjonen.
`}<inlineCode parentName="p">{`abortControllerRef.current.signal`}</inlineCode>{` inneholder et signal som sier fra når asynkrone kall bør avbrytes og er støttet ut av boksen i f.eks `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` og `}<inlineCode parentName="p">{`axios`}</inlineCode>{`.
Les mer om AbortController `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/AbortController"
      }}>{`på Mozilla sine sider`}</a>{` og se eksempel på alt i bruk under
`}<a parentName="p" {...{
        "href": "#dropdown-med-valg-fra-nettverkskall"
      }}>{`Dropdown med valg fra nettverkskall`}</a>{`.`}</p>
    <h3 {...{
      "id": "innebygd-nettleser-dropdown-med-styling-nativedropdown"
    }}>{`Innebygd nettleser-dropdown med styling (NativeDropdown)`}</h3>
    <p>{`Hvis du enten ønsker en veldig lettvektig dropdown eller en dropdown som bruker det innebygde grensesnittet til brukerens
enhet kan du bruke `}<inlineCode parentName="p">{`NativeDropdown`}</inlineCode>{`. Denne er style-et til å følge Entur sin merkevare, men når den trykkes på vil
nettleseren sin egen dropdown åpnes i stedet. Denne varianten er mye simplere og vil, av sin natur, ikke se lik ut
på tvers av enhetern når den åpnes. En slik løsning kan være praktisk i små, enkle løsninger eller, i noen tilfeller,
mobil-grensesnitt.`}</p>
    <p><inlineCode parentName="p">{`NativeDropdown`}</inlineCode>{` er i bunn en HTML-select-input – se `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select"
      }}>{`MDN web docs`}</a>{`
for mer info – og fungerer for det meste likt som den. Det er dog noen forskjeller, de viktigste er som følger:`}</p>
    <ul>
      <li parentName="ul">{`i stedet for å ha `}<inlineCode parentName="li">{`<option />`}</inlineCode>{` som children gir du inn liste med valg på samme måte som `}<inlineCode parentName="li">{`Dropdown`}</inlineCode>{` og `}<inlineCode parentName="li">{`SearchableDropdown`}</inlineCode>{` – se `}<a parentName="li" {...{
          "href": "#liste-med-valgalternaltiver"
        }}>{`Liste med valgalternaltiver`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onChange`}</inlineCode>{` er forskjellig fra den originale `}<inlineCode parentName="li">{`onchange`}</inlineCode>{` og ser ut som følger:`}<CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
          {`
  type onChange = (
    value: string;
    selectedItem: NormalizedDropdownItemType | null;
    target: EventTarget & HTMLSelectElement;
  ) => void;
`}
        </CodeBlock></li>
      <li parentName="ul">{`i stedet for `}<inlineCode parentName="li">{`selectedItem`}</inlineCode>{` brukes `}<inlineCode parentName="li">{`value`}</inlineCode>{`, dette på samme måte som i HTML sin select-input`}</li>
    </ul>
    <p>{`Se et eksempel på bruk av NativeDropdown under eksempelet `}<a parentName="p" {...{
        "href": "#native-dropdown"
      }}>{`Native dropdown`}</a></p>
    <h2 {...{
      "id": "universell-utforming"
    }}>{`Universell utforming`}</h2>
    <p>{`Kommer info om dette snart`}</p>
    <h2 {...{
      "id": "retningslinjer"
    }}>{`Retningslinjer`}</h2>
    <p>{`Kommer snart`}</p>
    <h2 {...{
      "id": "eksempler"
    }}>{`Eksempler`}</h2>
    <h3 {...{
      "id": "vanlig-dropdown"
    }}>{`Vanlig dropdown`}</h3>
    <p>{`Her finner du en vanlig, ikke-søkbar dropdown med standard-props.`}</p>
    <Playground __position={7} __code={'() => {\n  const [selected, setSelected] = React.useState(null)\n  return (\n    <Dropdown\n      label=\"Velg by\"\n      items={cities}\n      selectedItem={selected}\n      onChange={setSelected}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      alignItems: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState(null);
        return <Dropdown label="Velg by" items={cities} selectedItem={selected} onChange={setSelected} mdxType="Dropdown" />;
      }}
    </Playground>
    <h3 {...{
      "id": "søkbar-dropdown-1"
    }}>{`Søkbar dropdown`}</h3>
    <p>{`Her finner du en vanlig, søkbar dropdown med standard-props.`}</p>
    <Playground __position={8} __code={'() => {\n  const [selected, setSelected] = React.useState(null)\n  return (\n    <SearchableDropdown\n      label=\"Velg by\"\n      items={cities}\n      selectedItem={selected}\n      onChange={setSelected}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      alignItems: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState(null);
        return <SearchableDropdown label="Velg by" items={cities} selectedItem={selected} onChange={setSelected} mdxType="SearchableDropdown" />;
      }}
    </Playground>
    <h3 {...{
      "id": "søkbar-dropdown-med-egendefinert-filtreringsfunksjon"
    }}>{`Søkbar dropdown med egendefinert filtreringsfunksjon`}</h3>
    <p>{`I dette eksempelet brukes en fuzzyMatch-implementasjon til å utføre en mer tilgivende
filtrering av de tilgjengelige valgene.`}</p>
    <Playground __position={9} __code={'() => {\n  const [selected, setSelected] = React.useState(null)\n  return (\n    <SearchableDropdown\n      label=\"Fuzzy search filter\"\n      items={cities}\n      selectedItem={selected}\n      onChange={setSelected}\n      itemFilter={(item, inputValue) =>\n        fuzzyMatch(inputValue, item.label) > 0.5\n      }\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      alignItems: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState(null);
        return <SearchableDropdown label="Fuzzy search filter" items={cities} selectedItem={selected} onChange={setSelected} itemFilter={(item, inputValue) => fuzzyMatch(inputValue, item.label) > 0.5} mdxType="SearchableDropdown" />;
      }}
    </Playground>
    <h3 {...{
      "id": "dropdown-som-kan-tilbakestilles"
    }}>{`Dropdown som kan tilbakestilles`}</h3>
    <p>{`Hvis du øsker at brukeren kan fjerne valget sitt etter å ha gjort det kan du enten bruke prop-en `}<inlineCode parentName="p">{`clearable`}</inlineCode>{` eller selv sende inn
valgt verdien `}<inlineCode parentName="p">{`null`}</inlineCode>{` til `}<inlineCode parentName="p">{`selectedItem`}</inlineCode>{`-prop-en. `}<inlineCode parentName="p">{`clearable`}</inlineCode>{` legger til en knapp i dropdownen som fjerner valgt element.
Funksjonaliteten er lik for både vanlig og søkbar dropdown.`}</p>
    <Playground __position={10} __code={'() => {\n  const [selected, setSelected] = React.useState(null)\n  return (\n    <>\n      <span>\n        <IconButton onClick={() => setSelected(null)}>\n          <ResetIcon />\n          Tilbakestill\n        </IconButton>\n      </span>\n      <Dropdown\n        label=\"Velg by\"\n        items={cities}\n        selectedItem={selected}\n        onChange={setSelected}\n        clearable\n        labelClearSelectedItem=\"fjern valgt by\"\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      alignItems: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState(null);
        return <>
      <span><IconButton onClick={() => setSelected(null)} mdxType="IconButton"><ResetIcon mdxType="ResetIcon" />Tilbakestill</IconButton></span>
      <Dropdown label="Velg by" items={cities} selectedItem={selected} onChange={setSelected} clearable labelClearSelectedItem="fjern valgt by" mdxType="Dropdown" />
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "dropdown-med-valg-fra-nettverkskall"
    }}>{`Dropdown med valg fra nettverkskall`}</h3>
    <p>{`Her har vi en eksempelfunksjon `}<inlineCode parentName="p">{`fetchItems`}</inlineCode>{` som bruker `}<inlineCode parentName="p">{`abortControllerRef`}</inlineCode>{`-argumentet og henter inn data fra et test-API.
Denne funksjonen settes så inn i `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en og vi får data fra det eksterne API-et listet opp når data-en er mottatt.`}</p>
    <Playground __position={11} __code={'() => {\n  // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig\n  const fetchItems = React.useCallback(async () => {\n    try {\n      const response = await fetch(\n        `https://dummyjson.com/products/categories`,\n      )\n      const data = await response.json()\n      if (data.message !== undefined) return [data.message]\n      return data.map(item => ({ label: item.name, value: item.slug }))\n    } catch (error) {\n      console.error(\'noe galt\')\n      return []\n    }\n  }, [])\n  const [selected, setSelected] = React.useState(null)\n  return (\n    <Dropdown\n      label=\"Extenal API list\"\n      items={fetchItems}\n      selectedItem={selected}\n      onChange={setSelected}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      alignItems: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig
        const fetchItems = React.useCallback(async () => {
          try {
            const response = await fetch(`https://dummyjson.com/products/categories`);
            const data = await response.json();
            if (data.message !== undefined) return [data.message];
            return data.map(item => ({
              label: item.name,
              value: item.slug
            }));
          } catch (error) {
            console.error('noe galt');
            return [];
          }
        }, []);
        const [selected, setSelected] = React.useState(null);
        return <Dropdown label="Extenal API list" items={fetchItems} selectedItem={selected} onChange={setSelected} mdxType="Dropdown" />;
      }}
    </Playground>
    <h3 {...{
      "id": "søkbar-dropdown-med-valg-fra-nettverkskall-bassert-på-tekstinput"
    }}>{`Søkbar dropdown med valg fra nettverkskall bassert på tekstinput`}</h3>
    <p>{`Her har vi en eksempelfunksjon `}<inlineCode parentName="p">{`fetchItems`}</inlineCode>{` som henter inn data fra et test-API. `}<inlineCode parentName="p">{`fetchItems`}</inlineCode>{` tar inn
nåværende `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{` og `}<inlineCode parentName="p">{`abortControllerRef`}</inlineCode>{` og bruker det til å gjøre en query mot API-et. Denne funksjonen
settes så inn i `}<inlineCode parentName="p">{`items`}</inlineCode>{`-prop-en og vi får data fra det eksterne API-et listet opp med query bassert på
det som skrives inn.`}</p>
    <Playground __position={12} __code={'() => {\n  // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig\n  const fetchItems = React.useCallback(\n    async (inputValue, abortControllerRef) => {\n      try {\n        const response = await fetch(\n          `https://dummyjson.com/products/search?q=${inputValue}&limit=15&select=title`,\n          // Bruk signalet fra abortController for å avbryte utdaterte kall\n          { signal: abortControllerRef.current.signal },\n        )\n        const data = await response.json()\n        if (data.message !== undefined) return [data.message]\n        const processedData = data.products.map(item => {\n          return { label: item.title, value: item.id }\n        })\n        return processedData\n      } catch (error) {\n        // AbortError må sendes videre til komponenten for å håndtere cleanup riktig\n        if (error && error.name === \'AbortError\') throw error\n        console.error(\'noe galt\')\n        return []\n      }\n    },\n    [],\n  )\n\n  const [selected, setSelected] = React.useState(null)\n  return (\n    <SearchableDropdown\n      label=\"Extenal API list\"\n      items={fetchItems}\n      selectedItem={selected}\n      onChange={setSelected}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      alignItems: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        // Husk å bruke useCallback for å unngå at funksjonen kjøres oftere enn nødvendig
        const fetchItems = React.useCallback(async (inputValue, abortControllerRef) => {
          try {
            const response = await fetch(`https://dummyjson.com/products/search?q=${inputValue}&limit=15&select=title`, // Bruk signalet fra abortController for å avbryte utdaterte kall
            {
              signal: abortControllerRef.current.signal
            });
            const data = await response.json();
            if (data.message !== undefined) return [data.message];
            const processedData = data.products.map(item => {
              return {
                label: item.title,
                value: item.id
              };
            });
            return processedData;
          } catch (error) {
            // AbortError må sendes videre til komponenten for å håndtere cleanup riktig
            if (error && error.name === 'AbortError') throw error;
            console.error('noe galt');
            return [];
          }
        }, []);
        const [selected, setSelected] = React.useState(null);
        return <SearchableDropdown label="Extenal API list" items={fetchItems} selectedItem={selected} onChange={setSelected} mdxType="SearchableDropdown" />;
      }}
    </Playground>
    <h3 {...{
      "id": "native-dropdown"
    }}>{`Native dropdown`}</h3>
    <p>{`Her finner du en native dropdown med standard-props.`}</p>
    <Playground __position={13} __code={'() => {\n  const [selected, setSelected] = React.useState(null)\n  return (\n    <NativeDropdown\n      label=\"Velg by\"\n      items={cities}\n      value={selected}\n      onChange={({ value }) => setSelected(value)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Dropdown,
      SearchableDropdown,
      NativeDropdown,
      SmallExpandableAlertBox,
      ResetIcon,
      IconButton,
      ExpandableText,
      Playground,
      ImportStatement,
      PageHeader,
      dropdownItems,
      countries,
      cities,
      fuzzyMatch,
      CodeBlock,
      doPic,
      dontPic,
      LinkText,
      CodeText,
      DefaultLayout,
      _frontmatter
    }} style={{
      overflow: 'visible',
      alignItems: 'start',
      width: '50%'
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        const [selected, setSelected] = React.useState(null);
        return <NativeDropdown label="Velg by" items={cities} value={selected} onChange={({
          value
        }) => setSelected(value)} mdxType="NativeDropdown" />;
      }}
    </Playground>
    <h2 {...{
      "id": "tilgjengelige-props"
    }}>{`Tilgjengelige props`}</h2>
    <h3 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h3>
    <ImportStatement imports="Dropdown" mdxType="ImportStatement" />
    <Props of={Dropdown} mdxType="Props" />
    <h3 {...{
      "id": "searchabledropdown"
    }}>{`SearchableDropdown`}</h3>
    <ImportStatement imports="SearchableDropdown" mdxType="ImportStatement" />
    <Props of={SearchableDropdown} mdxType="Props" />
    <h3 {...{
      "id": "nativedropdown"
    }}>{`NativeDropdown`}</h3>
    <ImportStatement imports="NativeDropdown" mdxType="ImportStatement" />
    <Props of={NativeDropdown} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      